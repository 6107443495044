import { IconType } from "react-icons";
import { lazy } from "react";

export const FAQPage = lazy(() => import("../../pages/FAQ"));
export const TermsAndConditions = lazy(
  () => import("../../pages/TermsAndConditions")
);
export const Zoho = lazy(() => import("../../pages/Zoho"));
export const KnowledgeBase = lazy(() => import("../../pages/KnowledgeBase/KBHome"));
export const ReelsPage = lazy(() => import("../../pages/KnowledgeBase/Reels"));
export const KnowledgeBaseReels = lazy(() => import("../../components/organisms/KnowledgeBase/KBReelsOrganism"));
export const ArticlesPage = lazy(() => import("../../pages/KnowledgeBase/Article"));
export const ArticleDetailsPage = lazy(() => import("../../pages/KnowledgeBase/ArticleDetails"));
export const ResourcesPage = lazy(() => import("../../pages/KnowledgeBase/Resource"));
export const ReelsVideoClipPage = lazy(() => import("../../pages/KnowledgeBase/Reels/ReelsVideoClip"));
export const KBHomeDetailsViewPage=lazy(() => import("../../pages/KnowledgeBase/KBHome/KBHomeDetailsViewPage"));
export const FAQDeleteAndroidPage = lazy(() => import("../../pages/FAQDelete/FAQDeleteAndroid"));

const RoutesList: {
  path: string[];
  element?: React.LazyExoticComponent<() => JSX.Element> | any;
  name?: string | undefined;
  icon?: IconType | undefined;
  highlight?: string[];
}[] = [
    {
      path: [
        "/",
        "/faq/general",
        "/faq/orders",
        "/faq/product",
        "/faq/payments",
        "/faq/loyalty",
        "/faq/onboarding",
        "/faq/category",
        "/faq/brand",
        "/faq/quotes",
        "/faq/search",
        "/faq/cus_general",
        "/faq/cus_orders",
        "/faq/cus_product",
        "/faq/cus_payments",
        "/faq/cus_loyalty",
        "/faq/cus_onboarding",
        "/faq/cus_category",
        "/faq/cus_brand",
        "/faq/cus_quotes",
        "/faq/cus_search",

      ],
      element: FAQPage,
      highlight: ["/faq"],
    },
    {
      path: ["/t&c/terms_condition", "/t&c/privacy_policy", "/t&c/oss_licence"],
      element: TermsAndConditions,
      highlight: ["/t&c"],
    },
    {
      path: ["/chat"],
      element: Zoho,

    },
    {
      path: ["/info_vault/kb", "/info_vault"],
      element: KnowledgeBase,
      highlight: ["/info_vault/kb"],
    },
    {
      path: ["/info_vault/kb/:kb_details"],
      element: KBHomeDetailsViewPage,
      highlight: ["/info_vault/kb"],
    },
    {
      path: ["/info_vault/reels"],
      element: ReelsPage,
      highlight: ["/info_vault/reels"],
    },
    {
      path: ["/info_vault/reels/:video_clip"],
      element: ReelsVideoClipPage,
      highlight: ["/info_vault/reels"],
    },
    {
      path: ["/info_vault/articles"],
      element: ArticlesPage,
      highlight: ["/info_vault/articles"],
    },
    {
      path: ["/info_vault/articles/article_details", "/info_vault/articles/article_details/:article_id"],
      element: ArticleDetailsPage,
      highlight: ["/info_vault/articles"],
    },
    {
      path: ["/info_vault/resources"],
      element: ResourcesPage,
      highlight: ["/info_vault/resources"],
    },
    {
      path: ["/info_vault/kb/reels"],
      element: KnowledgeBaseReels,
      highlight: ["/info_vault/kb"],
    },
    {
      path: ["/faq_delete_android"],
      element: FAQDeleteAndroidPage,
    },
  ];

export default RoutesList;
